// gatsby
import { navigate } from 'gatsby'
// react
import React, { createRef, useState, useEffect, useRef } from 'react'
// react bootstrap components
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
// external modules
import * as hdf5 from 'jsfive/index.js'
import JSZip from 'jszip'
import { v4 as uuidv4 } from 'uuid'
// ui
import 'bootstrap/dist/css/bootstrap.css'
import { BsListCheck } from 'react-icons/bs'
// layout
import Layout from 'components/layout'
// internal components
import AllStationOptions from 'components/options/station/all'
import AllUnitOptions from 'components/options/unit/all'
import { GlobalDispatchContext } from 'context/global_context_provider'
import EigPreviewGraph from 'components/graph/eig_preview_graph'
// internal functions
import upload from 'tools/aws/upload'
import { customDateFromNumber } from 'tools/date'
import { reviewEigHistoricHdf, buildCycleId } from 'tools/fileReview'

const features = [
  'CYEXP',
  'DATDAT'
]

const acceptedFileTypes = [
  'application/zip',
  'application/x-zip',
  'application/x-zip-compressed'
]

const HistoryAddPage = (props) => {
  const defaultStation = Object.entries(props.appInfo.stations)[0][0]
  const [review, setReview] = useState(null)
  const [file, setFile] = useState(null)
  const [fileName, setFilename] = useState('')
  const [station, setStation] = useState(defaultStation)
  const [unit, setUnit] = useState('')
  const [cycle, setCycle] = useState('')
  const [notes, setNotes] = useState('')
  const [owner, setOwner] = useState('')
  const [stationError, setStationError] = useState('')
  const [unitError, setUnitError] = useState('')
  const [cycleError, setCycleError] = useState('')
  const [hdf, setHdf] = useState(null)
  const [jobId, setJobId] = useState(uuidv4())
  const fileRef = useRef()
  const dispatch = React.useContext(GlobalDispatchContext)

  const reset = () => {
    fileRef.current.value = ''
    setReview(false)
    setFile('')
    setFilename('')
    setStation('')
    setUnit('')
    setCycle('')
    setStationError('')
    setUnitError('')
    setCycleError('')
    setNotes('')
    setHdf(null)
  }

  let emailProfile = null
  let nameProfile = ''
  const onLoadEnd = e => {
    const barr = e.target.result
    const hdf = new hdf5.File(barr, file.name)
    const cycleId = hdf.keys[0]
    const expectedId = buildCycleId(station, unit, cycle)
    if (cycleId !== expectedId) {
      const errorText = (
        'Failed validation: Uploaded .hdf5 root key does not match the '
        + 'expected cycle ID of ' + cycleId + '. Ensure the station, unit, & '
        + 'cycle are correct in the upload form'
      )
      dispatch({ type: 'removeAlert', id: jobId })
      dispatch({
        type: 'addAlert',
        toAdd: {
          type: 'warningText',
          text: errorText
        }
      })
    } else {
      const data = hdf.get(cycleId)
      setHdf(data)
    }
  }
  const loadFile = () => {
    dispatch({
      type: 'addAlert',
      toAdd: {
        type: 'loading',
        id: jobId,
        text: 'Reviewing file '
      }
    })
    const reader = new FileReader()
    reader.onloadend = onLoadEnd
    reader.readAsArrayBuffer(file)
  }
  useEffect(() => {
    if (hdf) {
      const result = reviewEigHistoricHdf(hdf)
      dispatch({ type: 'removeAlert', id: jobId })
      setReview(result)
    }
  }, [hdf])
  useEffect(() => {
    if (station) {
      const units = Object.keys(props.appInfo.stations[station].units)
      setUnit(units[0])
    }
  }, [station])

  if (props.data) {
    emailProfile = props.data.profile.email
    nameProfile = (
      props.data.profile.familyName + ', ' +
      props.data.profile.givenName
    )
  }
  const alertOnError = (error) => {
    dispatch({
      type: 'addAlert',
      toAdd: {
        type: 'warningText',
        text: 'Failed validation: ' + error
      }
    })
  }

  const uploadHistoricFile = async (event) => {
    const fileCopy = file
    const params = {
      station: station,
      unit: unit,
      cycle: cycle,
      owner: nameProfile,
      notes: notes,
      point_coverage: review.cycleCoverage,
      date_range: review.dateRange
    }
    console.log(params)
    reset()
    upload('eigen', 'historic', fileCopy, params, dispatch)
  }

  return (
    <Container>
      <Row xs='auto' className='justify-content-center'>
        <Col xs='auto'>
          <Row className='mb-4'>
            <h1
              className='featurette-heading text-center'
              style={{ fontWeight: '100' }}
            >
              Upload Historical Data for Model Training
            </h1>
            <br />
            <br />
          </Row>
          <Card className='justify-content-center' style={{ border: 'none' }}>
            <Card.Body>
              <Form className='justify-content-center'>
                <Row className='mb-3'>
                  <Col xs='4'>
                    <Form.Group>
                      <Form.Label> Station </Form.Label>
                      <Form.Select
                        size='sm'
                        value={station}
                        onChange={e => setStation(e.target.value)}
                      >
                        <AllStationOptions />
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs='2'>
                    <Form.Group>
                      <Form.Label> Unit </Form.Label>
                      <Form.Select
                        size='sm'
                        value={unit}
                        onChange={e => setUnit(e.target.value)}
                      >
                        <AllUnitOptions station={station} />
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs='2'>
                    <Form.Group>
                      <Form.Label> Cycle </Form.Label>
                      <Form.Control
                        className='justify-content-center'
                        size='sm'
                        type='number'
                        min='0'
                        value={cycle}
                        onChange={e => setCycle(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs='6'>
                    <Form.Group>
                      <Form.Label> File </Form.Label>
                      <br />
                      <Form.Control
                        type='file'
                        size='sm'
                        ref={fileRef}
                        onChange={e => setFile(e.target.files[0])}
                      />
                      <Form.Text
                        className='text-muted'
                        style={{ fontWeight: '100' }}
                      >
                        Please select historical archive to upload (.hdf5)
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs='6'>
                    <Form.Group>
                      <Form.Label> Owner </Form.Label>
                      <Form.Control
                        disabled
                        type='text'
                        size='sm'
                        value={nameProfile}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Col xs='8'>
                    <Form.Group>
                      <Form.Label> Notes</Form.Label>
                      <Form.Control
                        as='textarea'
                        size='sm'
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                      />
                      <Form.Text
                        className='text-muted'
                        style={{ fontWeight: '100' }}
                      >
                        Optional
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mb-3'>
                  <Form.Switch
                    type='switch'
                    label='This is a complete cycle'
                  />
                </Row>
                <Row className='mb-3 justify-content-center'>
                  <Button
                    variant='outline-primary'
                    style={{ width: '25%', borderRadius: '25px' }}
                    onClick={loadFile}
                  >
                    Review
                  </Button>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row xs='auto' className='justify-content-center'>
        <Col xs='auto'>
          {
            (review)
              ? <EigPreviewGraph hdf={hdf} />
              : null
          }
        </Col>
      </Row>

      {
        (review)
          ? (
            <>
              <Row xs='auto' className='justify-content-center'>
                <Col xs='auto'>
                  <Form
                    className='fw-bold alert alert-dismissible fade show'
                    style={{ fontWeight: '100' }}
                  >
                    <Row
                      xs='auto'
                      className='mb-3 justify-content-left text-info lead'
                    >
                      Please verify the data below
                      <Col xs='1'>
                        <BsListCheck
                          className='fw-bold text-info'
                          style={{ width: '42', height: '42' }}
                          title='Please verify the data below'
                        />
                      </Col>
                    </Row>
                    <Form.Group
                      as={Row}
                      controlId='formPlaintext'
                    >
                      <Form.Label column xs='4'>
                        Date Range:
                      </Form.Label>
                      <Col xs='8'>
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={review.dateRange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId='formPlaintext'
                    >
                      <Form.Label column xs='4'>
                        Eigenvalue Points:
                      </Form.Label>
                      <Col xs='8'>
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={review.mcoPoints}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId='formPlaintext'
                    >
                      <Form.Label column xs='4'>
                        Eigenvalue Range:
                      </Form.Label>
                      <Col xs='8'>
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={review.mcoRange}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId='formPlaintext'
                    >
                      <Form.Label column xs='4'>
                        Eigenvalue Average:
                      </Form.Label>
                      <Col xs='8'>
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={review.mcoAverage}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId='formPlaintext'
                    >
                      <Form.Label column xs='4'>
                        State Points:
                      </Form.Label>
                      <Col xs='8'>
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={review.statePoints}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId='formPlaintext'
                    >
                      <Form.Label column xs='4'>
                        Cycle Coverage:
                      </Form.Label>
                      <Col xs='8'>
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={review.cycleCoverage}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId='formPlaintext'
                    >
                      <Form.Label column xs='4'>
                        Cycle Exposure Range:
                      </Form.Label>
                      <Col xs='8'>
                        <Form.Control
                          plaintext
                          readOnly
                          defaultValue={review.exposureRange}
                        />
                      </Col>
                      <Row className='mb-3 justify-content-center .mx-xs-auto'>
                        <Col xs='2' />
                        <Col xs='10'>
                          <Button
                            variant='outline-primary'
                            style={{ width: '35%', borderRadius: '25px' }}
                            onClick={uploadHistoricFile}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </>
            )
          : null
      }
    </Container>
  )
}

HistoryAddPage.Layout = Layout

export default HistoryAddPage
